import React, {memo, useEffect, useState} from 'react';
import moment from 'moment';
import Layout from '../../../components/layout';
import Result from '../../../components/result';
import {getRequestById} from '../../../requests';
import loop from '../../../img/icons/search-icon.svg';
import {navigate} from 'gatsby';
import icon1 from '../../../img/icons/icon-1.svg';
import icon2 from '../../../img/icons/icon-2.svg';
import icon3 from '../../../img/icons/icon-3.svg';
import icon4 from '../../../img/icons/icon-4.svg';
import {playIcon} from '../../../img/svg-icons';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Item = ({params: {id}}) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scanDate, setScanDate] = useState(null);
  const [usernameValue, setUsernameValue] = useState('');
  const [initialName, setInitialName] = useState('');
  const {t, i18n} = useTranslation();

  const getInfo = async () => {
    setLoading(true);
    const res = await getRequestById(id);
    res && res.result && setResult(res.result);
    res && res.source && setUsernameValue(res.source);
    res && res.source && setInitialName(res.source);
    res && res.scanned_at && setScanDate(res.scanned_at);
    setLoading(false);
  };

  useEffect(() => {
    getInfo();
  }, [id]);

  const getScanDate = () => {
    let scanTime = '';
    if (scanDate) {
      moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY') ===
      moment.utc(new Date().toISOString()).format('MMM DD, YYYY')
        ? (scanTime = moment.utc(new Date(scanDate).toISOString()).fromNow())
        : (scanTime = moment.utc(new Date(scanDate).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)'));
    } else {
      scanTime = t('a-few-seconds-ago"');
    }
    return scanTime;
  };

  const handleCaptureOnEnter = e => {
    if (e.key === 'Enter') {
      handleGo();
    }
  };

  const handleNewScanRequest = () => {
    const navLink = usernameValue.trim();
    //const navLinkArr = trimedValue.includes('@') ? trimedValue.match(/^([^@]*)@/) : null;
    //const navLink = navLinkArr ? navLinkArr[1] : trimedValue;
    navigate(`${i18n.language === 'ar' ? '/ar' : ''}/${navLink.toLocaleLowerCase()}/`);
  };

  const handleGo = () => {
    const navLink = usernameValue.trim();
    //const navLinkArr = trimedValue.includes('@') ? trimedValue.match(/^([^@]*)@/) : null;
    //const navLink = navLinkArr ? navLinkArr[1] : trimedValue;
    navigate(`${i18n.language === 'ar' ? '/ar' : ''}/${navLink.toLocaleLowerCase()}/`);
  };

  const handleChange = e => {
    setUsernameValue(e.target.value);
  };

  return (
    <Layout>
      <div className="search-wrapper position-relative" showLogo>
        <img
          src={icon4}
          style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '6%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon1}
          style={{width: '25px', height: 'auto', position: 'absolute', top: '0%', left: '12%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon1}
          style={{width: '25px', height: 'auto', position: 'absolute', top: '-25%', left: '35%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon3}
          style={{width: '70px', height: 'auto', position: 'absolute', top: '-20%', left: '18%'}}
          className="d-none d-md-block"
        />
        <img src={icon3} style={{width: '45px', height: 'auto', position: 'absolute', top: '-15%', left: '5%'}} />
        <img
          src={icon3}
          style={{width: '70px', height: 'auto', position: 'absolute', top: '15%', left: '18%'}}
          className="d-none d-smdblock"
        />

        <img src={icon1} style={{width: '35px', height: 'auto', position: 'absolute', top: '-20%', left: '90%'}} />
        <img src={icon2} style={{width: '35px', height: 'auto', position: 'absolute', top: '-30%', left: '65%'}} />
        <img
          src={icon2}
          style={{width: '40px', height: 'auto', position: 'absolute', top: '30%', left: '75%'}}
          className="d-none d-md-block"
        />
        <img
          src={icon4}
          style={{width: '45px', height: 'auto', position: 'absolute', top: '25%', left: '88%'}}
          className="d-none d-md-block"
        />
        <img src={icon3} style={{width: '70px', height: 'auto', position: 'absolute', top: '5%', left: '80%'}} />

        <h1 className="page-title">
          Candidate Checker {t('results-for')} {initialName}
        </h1>
        <h2 className="page-subtitle mb-4">
          {t('description-1')} <br />
          {t('description-2')}
        </h2>
        <div className={`form-container field is-grouped ${i18n.language === 'ar' ? 'left-align' : ''}`}>
          <input
            className={`input is-rounded inputBar ${i18n.language === 'ar' ? 'typing-rl' : ''}`}
            type="text"
            placeholder={t('nickname-or-username')}
            value={usernameValue}
            onChange={handleChange}
            onKeyPress={handleCaptureOnEnter}
            maxLength={320}
          />
          <img src={loop} style={{width: '25px', height: '25px', position: 'absolute', top: '15px', left: '15px'}} />
          <div className="buttons is-block btn_capture">
            <button className={`button is-primary is-outlined is-medium is-rounded inputBarButton`} onClick={handleGo}>
              {playIcon}
            </button>
          </div>
        </div>
      </div>
      <div className="w-100">
        {loading ? (
          <div className="control pt-3">
            <div className="is-flex justify-content-center text-center">
              <progress className="progress is-small is-primary" max="100" style={{maxWidth: '600px'}}>
                5%
              </progress>
            </div>
          </div>
        ) : (
          result && (
            <Result
              result={result}
              name={initialName}
              headerCenter={
                <div className="scan-info">
                  {
                    <div className="py-1 time-scraped">
                      {t('scraped')} {getScanDate()}
                      <div onClick={() => handleNewScanRequest()} className="rescan-btn">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            className="svg-icon"
                            d="M13.8064 6.27219C13.6639 6.25647 13.0783 6.19183 12.997 6.18283C12.6022 3.0042 9.89065 0.476273 6.52373 0.476273C2.9181 0.476273 0 3.39404 0 7C0 10.6057 2.91777 13.5237 6.52373 13.5237C8.41816 13.5237 10.1314 12.7163 11.3298 11.4114C11.543 11.1793 11.5276 10.8183 11.2955 10.6051C11.0634 10.3919 10.7023 10.4073 10.4892 10.6394C9.50504 11.711 8.09452 12.3824 6.52373 12.3824C3.55586 12.3824 1.14133 9.96786 1.14133 7C1.14133 4.03214 3.55586 1.6176 6.52373 1.6176C9.16861 1.6176 11.374 3.53524 11.8226 6.05319C11.6639 6.03567 11.8205 6.05295 10.9756 5.95968C10.8089 5.94128 10.6852 6.1107 10.7534 6.26388L11.9015 8.84163C11.9691 8.99339 12.1751 9.01614 12.2742 8.88278L13.9569 6.61751C14.0569 6.48293 13.9731 6.29059 13.8064 6.27219Z"
                            fill="#BBC2C5"
                          />
                        </svg>
                      </div>
                    </div>
                  }
                </div>
              }
            />
          )
        )}
      </div>
    </Layout>
  );
};

export default memo(Item);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
